<template>
    <div v-if="obj" class="mian-mobile">
        <img
            src="../../assets/cz_bt.png"
            class="rechargeBtn"
            :class="{ outWindowClass: rechargeBtnState }"
            @click="clickTurnToRecharge"
        />
        <img class="head" :src="imgUrl('payment_logo', 'jpg')" />
        <img v-show="!is_code_iOS" class="prop-title" :src="imgUrl('payment_twice_title_props')" />
        <div class="prop-detail">
            完成至尊充值的用户可随机获得一款
            <span>专属定制头像框</span>
            <br />
            每月更新18款，需在天天狼人APP内领取
        </div>
        <!-- <div class="prop-detail">官方将在10月25日之后，在集市回购【甜茶萌兔】</div> -->
        <!-- 头像框 -->
        <div v-show="!is_code_iOS" v-if="obj" class="prop-content">
            <!-- 动态头像1 -->
            <div class="prop-img frame-1">
                <img :src="obj.official_h5_1_url" alt="" />
                <!-- <img v-if="obj[0].pic_pc" class="avatar-img" :src="obj[0].pic_pc" />
                <img v-if="obj[0].pic_mob" class="avatar-img layer-2" :src="obj[0].pic_mob" />
                <img v-if="obj[0].pic_word" class="avatar-img layer-3" :src="obj[0].pic_word" /> -->
            </div>
            <!-- <div class="sale-num sale-num-1">{{ obj[0].selled_show_num }}/{{ obj[0].sell_num }}</div> -->

            <!-- 动态头像2 -->
            <div class="prop-img frame-2">
                <img :src="obj.official_h5_2_url" alt="" />
                <!-- <img v-if="obj[1].pic_pc" class="avatar-img" :src="obj[1].pic_pc" />
                <img v-if="obj[1].pic_mob" class="avatar-img layer-2" :src="obj[1].pic_mob" />
                <img v-if="obj[1].pic_word" class="avatar-img layer-3" :src="obj[1].pic_word" /> -->
            </div>
            <!-- <div class="sale-num sale-num-2">{{ obj[1].selled_show_num }}/{{ obj[1].sell_num }}</div> -->

            <!-- 动态头像1 -->
            <div class="prop-img frame-3">
                <img :src="obj.official_h5_3_url" alt="" />
                <!-- <img v-if="obj[2].pic_pc" class="avatar-img" :src="obj[2].pic_pc" />
                <img v-if="obj[2].pic_mob" class="avatar-img layer-2" :src="obj[2].pic_mob" />
                <img v-if="obj[2].pic_word" class="avatar-img layer-3" :src="obj[2].pic_word" /> -->
            </div>
            <!-- <div class="sale-num sale-num-3">{{ obj[2].selled_show_num }}/{{ obj[2].sell_num }}</div> -->

            <!-- 钻石数 -->
            <img ref="recharge" :src="imgUrl(`mx498888`)" class="priceImg" />

            <!-- 充值按钮 -->
            <div class="buy-frame-btn" @click="clickRecharge(14)">
                <img :src="imgUrl(`m19998btn`)" class="buyBtnImg" />
            </div>

            <!-- 定制流程 -->
            <img :src="imgUrl('payment_detail_btn2', 'png', true)" class="detail-img" @click="clickDetailImg" />
        </div>
        <img v-show="!is_code_iOS" :src="imgUrl('payment_first_title')" class="recharge-title" />
        <div v-if="!is_code_iOS" class="itemDiv">
            <div class="item-title">钻石可用于购买天天狼人的礼物和道具</div>
            <div class="item-content">
                <div class="search-input">
                    <input
                        id="userNo"
                        class="inputStyle searchInput"
                        placeholder="请输入天狼数字ID"
                        type="tel"
                        :oninput="showNickName()"
                        v-model="userNo"
                        @focus="focusFun"
                    />
                    <!-- <div class="searchBtn" @click="clickSearchBtn">查询</div> -->
                </div>
                <input
                    id="nickName"
                    class="inputStyle"
                    placeholder="根据ID显示昵称确认充值是否正确"
                    type="text"
                    readonly="readonly"
                    v-model="nickName"
                />
            </div>
            <div class="item-style">
                <img
                    v-for="(item, index) in 9"
                    :key="index"
                    :src="imgUrl(getImgString(index + 1), 'png', index > 5 ? true : false)"
                    class="item-img"
                    @click="clickRecharge(index + 1 == 9 ? 14 : index + 1)"
                />
                <img :src="imgUrl('mob-payment_item_god_emperor')" class="item-img" @click="handleClickBuyGodEmperor" />
                <img :src="imgUrl('mob-payment_item_lettering_998')" class="item-img" @click="clickRecharge(18)" />
            </div>
        </div>
        <div class="is_iOS_notice" v-else>
            由于相关规范，iOS公众号充值暂不可用，请点击右上角在浏览器打开后进行微信充值。
        </div>

        <!-- footer -->
        <div class="footer-notice" style="height: 30vw; margin-top: 50px; bottom: 0" :style="getIsIonsClass()">
            <center>
                <a href="https://beian.miit.gov.cn/" target="_blank">
                    <span style="color: white; font-size: 8px; margin-top: 8px">
                        增值电信业务经营许可证：辽B2-20170046
                    </span>
                </a>
                <a href="http://megatech.cc/officialWebsite/images/mega_zengzhidianxin.jpg" target="_blank">
                    <span style="color: white; font-size: 8px; margin-top: 8px; margin-bottom: 15px; margin-left: 8px">
                        辽B2-20170046-2
                    </span>
                </a>
            </center>
            <center>
                <a
                    target="_blank"
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010302000476"
                    style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
                >
                    <img src="../../assets/icpIcon.png" style="float: left" />
                    <p
                        style="
                            color: white;
                            font-size: 8px;
                            float: left;
                            height: 20px;
                            line-height: 20px;
                            margin: 0px 0px 0px 5px;
                        "
                    >
                        辽公网安备 21010302000476号
                    </p>
                </a>
            </center>
            <center>
                <div style="color: white; font-size: 8px">
                    Copyright © 2016 - 2020 Mega Information Technology Co.,Ltd. All Rights Reserved
                </div>
            </center>
        </div>

        <!-- 支付方式 -->
        <div v-if="showChooseWay" class="choose-way-dialog" @touchmove.prevent>
            <div class="dialog-content-m">
                <div class="dialog-title-m">请选择支付方式</div>
                <div
                    class="payaway-btn alipay-payaway-btn"
                    :class="[payAway == 1 ? 'payaway-alipay-s' : 'payaway-alipay']"
                    @click="payAway = 1"
                ></div>
                <div
                    class="payaway-btn"
                    :class="[payAway == 2 ? 'payaway-wechat-s' : 'payaway-wechat']"
                    @click="payAway = 2"
                ></div>
                <div class="opera-btn confirm-btn-m" @click="clickConfirmBtn">确定</div>
                <div class="opera-btn cancel-btn-m" @click="clickCancelBtn">考虑一下</div>
            </div>
        </div>

        <!-- 支付结果 -->
        <div v-if="payResult" class="choose-way-dialog" @touchmove.prevent>
            <div class="dialog-content-m confirm-pay-dialog">
                <div style="width: 90%; color: black">
                    <div style="width: 100%; text-align: center; margin-bottom: 2vw">购买成功</div>
                    <div>商品名：{{ payResult.msg.name }}</div>
                    <div>订单号：{{ payResult.msg.out_trade_no }}</div>
                </div>
                <div class="confirm-pay-btn confirm-btn-m" @click="clickPayResult">确定</div>
            </div>
        </div>
        <div v-if="open_notice" class="open_notice">
            <img :src="imgUrl('open_notice')" />
        </div>
        <mobSelectItem v-if="MOBChooseItem" :mobObj="obj"></mobSelectItem>
        <mobOpeningEmperor v-if="showPcOpeningEmperor"></mobOpeningEmperor>
    </div>
</template>

<script>
import {
    getNickname,
    wechatH5Pay,
    wechatH5Query,
    wechatJsPay,
    getUser_office_json,
    getUserios
} from "./../../apis/officialWeb";
import { getParam, getVisualHeightOfTheScreen } from "./../../utils/utils";
export default {
    name: "",
    props: ["obj"],
    data() {
        return {
            userNo: null,
            nickName: "",
            userNoCache: null,
            showChooseWay: false,
            index: null,
            payAway: 0,
            payResult: null,
            in_weixin: false,
            code: null,
            WeixinJSMsg: null,
            new_out_trade_no: null,
            is_code_iOS: false,
            open_notice: false,
            MOBChooseItem: false,
            rechargeDic: {
                1: "6",
                2: "30",
                3: "68",
                4: "128",
                5: "328",
                6: "648",
                7: "2998",
                8: "6888",
                14: "19998",
                15: "29998"
            },
            rechargeBtnState: 0,
            showPcOpeningEmperor: false
        };
    },
    components: {
        mobSelectItem: () => import("./mobSelectItem"),
        mobOpeningEmperor: () => import("./mobOpeningEmperor")
    },
    created() {
        var integrityurl = window.location.href;
        //console.log("当前url=>", integrityurl);
        var is_weixin = (function () {
            return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
        })();
        if (is_weixin) {
            // console.log("微信浏览器");
            this.in_weixin = true;
            let code = getParam("code");
            if (code) {
                //console.log("code=>", code);
                // console.log("公众号模式");
                this.code = code;
                if (/(iPhone|iPad|iPod|iOS|Mac OS X)/i.test(navigator.userAgent)) {
                    //判断苹果环境，屏蔽充值
                    this.open_notice = true;
                    this.is_code_iOS = true;
                    // window.alert(
                    //   "由于相关规范，iOS微信充值功能暂不可用，请跳转到官网进行充值。"
                    // );
                }
            }
        } else {
            //console.log("非微信浏览器");
        }
        let out_trade_no = getParam("out_trade_no");
        // //console.log("out_trade_no=>", out_trade_no);
        if (out_trade_no) {
            //判断是否有订单号,有订单号弹出选择界面
            if (out_trade_no.indexOf("alipay") > -1) {
                if (out_trade_no.split("-")[1] == 16 || out_trade_no.split("-")[1] == 17) {
                    // let userNo = out_trade_no.split("-")[2]
                    // let phone = getCookie(userNo)
                    // let cookie = document.cookie;
                    this.handleClickBuyGodEmperor();
                } else if (out_trade_no.split("-")[1] == 14) {
                    // this.MOBChooseItem = true;
                } else if (out_trade_no.split("-")[1] == 18) {
                    // this.PCChooseItem = true;
                }
            } else {
                let result = confirm(`支付是否完成？`);
                if (result) {
                    wechatH5Query({
                        out_trade_no
                    }).then((response) => {
                        //console.log("wechatH5Query response=>", response);
                        if (response.sign == 0) {
                            //{{`${payResult.msg.name}充值成功！订单号：${payResult.msg.out_trade_no}`}}
                            this.payResult = response;
                        } else {
                            window.alert(response.msg);
                            window.location.href = window.location.href.split("?")[0];
                        }
                    });
                } else {
                    //点击了取消
                    window.location.href = window.location.href.split("?")[0];
                }
            }
        }
    },
    mounted() {
        let offsetTop = (getVisualHeightOfTheScreen()[0] / 100) * 16;
        window.addEventListener("scroll", () => {
            //滚动偏移量
            let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
            if (top >= this.$refs.recharge.offsetTop - offsetTop) {
                // console.log("偏移位置");
                this.rechargeBtnState = 1;
            } else {
                // console.log("定位位置");
                this.rechargeBtnState = 0;
            }
        });
    },
    methods: {
        clickTurnToRecharge: function () {
            window.scrollTo(0, this.$refs.recharge.offsetTop - (getVisualHeightOfTheScreen()[0] / 100) * 16 + 1);
        },
        clickPayResult: function () {
            this.payResult = null;
            window.location.href = window.location.href.split("?")[0];
        },
        getIsIonsClass: function () {
            if (this.is_code_iOS) {
                return "position: absolute;";
            }
        },
        clickCancelBtn: function () {
            this.showChooseWay = false;
            this.payAway = 0;
        },
        clickConfirmBtn: function () {
            if (this.payAway == 0) {
                return;
            }
            //支付宝
            if (this.payAway == 1) {
                if (this.in_weixin) {
                    this.open_notice = true;
                    this.showChooseWay = false;
                    return;
                } else {
                    getUserios({
                        userNo: this.userNo,
                        type: this.index
                    }).then((response) => {
                        if (response.sign == "0") {
                            let div = document.createElement("div");
                            let msg =
                                response.msg.split("/alipay/")[0] +
                                "https://werewolf.53site.com/alipay/" +
                                response.msg.split("/alipay/")[1];
                            div.innerHTML = msg;
                            document.body.appendChild(div);
                            if (/(iPhone|iPad|iPod|iOS|Mac OS X)/i.test(navigator.userAgent)) {
                                document.forms[0].submit();
                            } else {
                                document.forms[0].setAttribute("target", "_blank");
                                document.forms[0].submit();
                            }
                            document.forms[0].remove();

                            // console.log("msg=>", document.forms);
                        } else {
                            window.alert(response.msg);
                        }
                    });
                }
            }

            //微信
            if (this.payAway == 2) {
                //console.log("index=>", this.index);
                //console.log("this.userNo=>", this.userNo);
                //console.log("this.code=>", this.code);

                if (this.in_weixin) {
                    if (this.code) {
                        //console.log("公众号模式");
                        //console.log("调用微信JS支付");
                        wechatJsPay({
                            userNo: this.userNo,
                            type: this.index,
                            code: this.code
                        }).then((response) => {
                            //console.log(response);
                            if (response.sign == 0) {
                                this.WeixinJSMsg = response.msg.jsApiParameters;
                                this.new_out_trade_no = response.msg.out_trade_no;
                                //console.log("this.new_out_trade_no=>", this.new_out_trade_no);
                                //拉起微信js支付
                                this.WeixinJSPay();
                            } else {
                                //code失效 重新刷新页面
                                if (response.sign == 4001) {
                                    window.location.href = `/Werewolf/officialWeb/wechatJsPayPage.php?userNo=${this.userNo}&type=${this.index}`;
                                } else {
                                    window.alert(response.msg);
                                }
                            }
                        });
                    } else {
                        //console.log("浏览器模式");
                        //do something
                        window.location.href = `/Werewolf/officialWeb/wechatJsPayPage.php?userNo=${this.userNo}&type=${this.index}`;
                    }
                } else {
                    //console.log("调用微信H5支付");
                    wechatH5Pay({
                        userNo: this.userNo,
                        type: this.index
                    }).then((response) => {
                        //console.log(response);
                        // this.payAway = 0;
                        window.location.href = response.msg.mweb_url;
                    });
                }
            }
        },
        WeixinJSPay: function () {
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady, false);
                } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
                    document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
                }
            } else {
                this.onBridgeReady();
            }
        },
        onBridgeReady: function () {
            let _this = this;
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke("getBrandWCPayRequest", this.WeixinJSMsg, function (res) {
                //console.log("res.err_msg=>", res.err_msg);
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    let integrityurl = window.location.href;
                    //console.log("user-this.new_out_trade_no=>", _this.new_out_trade_no);
                    let new_url = `${integrityurl.split("?")[0]}?out_trade_no=${_this.new_out_trade_no}`;
                    //console.log("new_url=>", new_url);
                    //console.log("new_url type=>", typeof new_url);

                    window.location.href = new_url;
                }
            });
        },
        getImgString: function (index) {
            if (index == 9) {
                return this.$parent.getString(index).split("_for_pc_1")[0] + "_for_phone";
            }
            return this.$parent.getString(index).split("_for_pc_1")[0];
        },
        clickRecharge: function (index) {
            // console.log("点击了充值", index);
            if (!this.userNo) {
                return window.alert("请输入天狼数字ID");
            }
            if (!this.nickName) {
                return window.alert("请查询昵称确认充值是否正确");
            }
            this.index = index;
            let str = "";
            if (index == 18) {
                str = `确定要给 ID：${this.userNo} 昵称：${this.nickName} 购买至尊专属刻字服务吗？`;
            } else {
                str = `确定要给 ID：${this.userNo} 昵称：${this.nickName} 充值${this.rechargeDic[index]}元吗？`;
            }
            let result = confirm(str);
            //点击了确认
            if (result) {
                // //console.log("点击了确认充值");
                if (this.index > 6 && this.index != 18) {
                    //充值了大额
                    if (this.in_weixin) {
                        //微信浏览器内打开 指引外部浏览器
                        this.open_notice = true;
                    } else {
                        if (index == 14) {
                            //TODO调用新大额充值Alipay带回调
                            getUserios({
                                userNo: this.userNo,
                                type: index
                            }).then((response) => {
                                // console.log(response);
                                if (response.sign == "0") {
                                    let div = document.createElement("div");
                                    let msg =
                                        response.msg.split("/alipay/")[0] +
                                        "https://werewolf.53site.com/alipay/" +
                                        response.msg.split("/alipay/")[1];
                                    div.innerHTML = msg;
                                    document.body.appendChild(div);
                                    document.forms[0].submit();
                                } else {
                                    window.alert(response.msg);
                                }
                            });
                        } else {
                            getUserios({
                                userNo: this.userNo,
                                type: this.index
                            }).then((response) => {
                                // console.log(response);
                                if (response.sign == "0") {
                                    let div = document.createElement("div");
                                    let msg =
                                        response.msg.split("/alipay/")[0] +
                                        "https://werewolf.53site.com/alipay/" +
                                        response.msg.split("/alipay/")[1];
                                    div.innerHTML = msg;
                                    document.body.appendChild(div);
                                    document.forms[0].submit();
                                    document.forms[0].remove();
                                    // let data = response.msg;
                                    //  window.location.href = `https://werewolf.53site.com/alipay/api/alipayapi_office.php?WIDout_trade_no=${data.userNo}&WIDsubject=${data.WIDsubject}$WIDtotal_fee=${data.type}&WIDshow_url=${data.WIDshow_url}`
                                } else {
                                    window.alert(response.msg);
                                }
                            });
                        }
                    }
                } else {
                    this.showChooseWay = true;
                }
            } else {
                //console.log("点击了取消充值");
            }
        },
        showNickName: function () {
            if (this.userNo && this.userNo.length > 15) {
                this.userNo = this.userNo.substring(0, 15);
            }
            if (!this.userNo) {
                return;
            }
            this.userNo = this.userNo.replace(/[^\d]/g, "");
            this.userNoCache = this.userNo;
            getNickname({
                userNo: this.userNo
            }).then((response) => {
                //   //console.log(response.msg);
                this.nickName = response.msg;
            });
            //   //console.log(this.userNo);
        },
        clickSearchBtn: function () {
            //   //console.log("查询用户昵称");
            if (this.userNo) {
                if (this.userNo == this.userNoCache) {
                    return;
                }
                this.userNoCache = this.userNo; //缓存userNo防止重复点击
                getNickname({
                    userNo: this.userNo
                }).then((response) => {
                    //   //console.log(response.msg);
                    this.nickName = response.msg;
                });
            } else {
                window.alert("请输入天狼数字ID");
            }
        },
        clickDetailImg: function () {
            this.$parent.showMobileDialog = true;
        },
        focusFun: function () {
            this.nickName = "";
        },
        handleClickBuyGodEmperor: function () {
            // console.log('点击了购买神皇按钮');
            this.showPcOpeningEmperor = true;
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../style/main.scss";

.mian-mobile {
    width: 100%;
    height: 100%;
    min-height: 100vh;

    .head {
        width: 100%;
    }

    .rechargeBtn {
        width: 21.3vw;
        position: fixed;
        right: 0;
        top: 68vw;
        z-index: 11;
        transition: all 0.2s linear;
    }

    .outWindowClass {
        right: -21.3vw;
    }

    .prop-title {
        width: 50vw;
        margin-top: 5vw;
        margin-left: 25vw;
    }

    .prop-content {
        width: 100vw;
        height: 385.4vw;
        @include imgbg(imgUrl("frame_bg_mob.png"));
        // background-color: red;
        overflow: hidden;
    }

    .prop-detail {
        width: 100vw;
        font-size: 4vw;
        text-align: center;
        color: #fff;
        box-sizing: border-box;
        padding-top: 6.8vw;
        height: 22.8vw;
        background-color: #250534;
        span {
            color: #ffbc55;
        }
    }

    .sale-num {
        width: 100vw;
        text-align: center;
        font-size: 8.1vw;
        color: #ffbc55;
        line-height: 8.1vw;
        font-family: Alibaba-PuHuiTi-H;
    }

    .sale-num-1 {
        margin-top: 4.5vw;
    }

    .sale-num-2 {
        margin-top: 2.9vw;
    }

    .sale-num-3 {
        margin-top: 2.9vw;
    }

    .prop-img {
        width: 85vw;
        height: 70.9vw;
        // margin-left: 7.5vw;
        img {
            width: 100vw;
            height: 72.5vw;
            margin: 0 auto;
            display: block;
        }
    }

    .frame-1 {
        margin-top: 15.9vw;
        height: 83.5vw;
    }

    .frame-2 {
        margin-top: 24vw;
        height: 81.9vw;
    }

    .frame-3 {
        margin-top: 27.5vw;
        height: 81.9vw;
    }

    .avatar-img {
        width: 85vw;
        height: 70.9vw;
        position: absolute;
    }

    .layer-2 {
        z-index: 1;
    }

    .layer-3 {
        z-index: 2;
    }

    .priceImg {
        width: 38.2vw;
        margin-top: 18vw;
        margin-left: 45.8vw;
    }

    .buy-frame-btn {
        margin-left: 10.5vw;
        margin-top: 9.3vw;
    }

    .buyBtnImg {
        width: 79.1vw;
    }

    .saleNum {
        width: 100%;
        height: 10vw;
        text-align: center;
        color: #ffbc55;
        line-height: 10vw;
        font-size: 3.2vw;
    }

    .detail-img {
        margin-left: 10.4vw;
        width: 79.2vw;
        margin-top: 8.1vw;
    }

    .recharge-title {
        width: 50%;
        margin-left: 25%;
        margin-top: 5vw;
    }

    .itemDiv {
        width: 95vw;
        background-color: #37094f;
        margin: 0 auto;
        margin-top: 2.5vw;
        border-radius: 2.5vw;
        padding-top: 2.5vw;
    }

    .item-title {
        width: 100%;
        padding: 0;
        position: relative;
        text-align: center;
        color: white;
        font-size: 3.2vw;
    }

    .is_iOS_notice {
        width: 90%;
        margin: 0 auto;
        color: #ffbc55;
        margin-top: 50px;
    }

    .footer-notice {
        width: 96%;
        margin-left: 2%;
        text-align: center;
    }

    .item-content {
        width: 100%;
        margin: 2% 0 0 0;
        padding: 0;
        position: relative;
    }

    .search-input {
        display: flex;
        -webkit-user-select: text !important;
    }

    .searchBtn {
        width: 15vw;
        height: 15vw;
        margin-left: -5%;
        margin-top: 2vw;
        margin-bottom: -10vw;
        background-color: #ffbc55;
        border-radius: 2.5vw;
        font-size: 3.2vw;
        text-align: center;
        line-height: 15vw;
    }

    .inputStyle {
        width: 80%;
        margin: 1% 10%;
        border-radius: 5px;
        border: none;
        padding: 1.5% 0;
        text-align: center;
        color: white;
        background-color: #250534;
        font-size: 3.2vw;
        -webkit-user-select: text !important;
    }

    .item-style {
        width: 95%;
        margin: 2% 2.5% 0 2.5%;
        position: relative;
    }

    .item-img {
        width: 90%;
        margin: 0 5%;
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: gray;
        font-size: 3.2vw;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        color: gray;
        font-size: 3.2vw;
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        color: gray;
        font-size: 3.2vw;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        color: gray;
        font-size: 3.2vw;
    }

    .choose-way-dialog {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        z-index: 10;
    }

    .dialog-content-m {
        width: 72vw;
        // height: 77.3vw;
        padding-bottom: 3.9vw;
        padding-top: 11.5vw;
        background-color: white;
        border-radius: 4vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        align-content: flex-start;
        -webkit-align-content: flex-start;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .dialog-title-m {
        color: #424242;
        font-size: 4vw;
        line-height: 4vw;
        font-weight: bold;
    }

    .payaway-btn {
        width: 65.3vw;
        height: 12.3vw;
        margin: 0 auto;
        margin-top: 2.7vw;
    }

    .alipay-payaway-btn {
        margin-top: 8vw;
    }

    .payaway-alipay {
        @include imgbg(imgUrl("alipay.png"));
    }

    .payaway-alipay-s {
        @include imgbg(imgUrl("alipay-s.png"));
    }

    .payaway-wechat {
        @include imgbg(imgUrl("wechatpay.png"));
    }

    .payaway-wechat-s {
        @include imgbg(imgUrl("wechatpay-s.png"));
    }

    .opera-btn {
        width: 65.3vw;
        height: 12.3vw;
        border-radius: 2.7vw;
        line-height: 12.3vw;
        font-size: 3.4vw;
        font-weight: bold;
        text-align: center;
    }

    .confirm-btn-m {
        background-color: #ffbc55;
        color: #845000;
        margin-top: 5.6vw;
    }

    .cancel-btn-m {
        background-color: #cbcbcb;
        color: #545454;
        margin-top: 2.7vw;
    }

    .confirm-pay-btn {
        width: 60%;
        margin-top: 4vw;
        text-align: center;
        border-radius: 2vw;
        line-height: 10vw;
        font-size: 3.4vw;
        font-weight: bold;
        text-align: center;
        color: #430807;
    }

    .confirm-pay-dialog {
        color: white;
        font-size: 3.2vw;
        padding-top: 5vw;
        display: flex;
    }

    .open_notice {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        z-index: 20;

        img {
            width: 100%;
        }
    }
}

body::-webkit-scrollbar {
    display: none;
}

.mian-mobile::-webkit-scrollbar {
    display: none;
}
</style>
